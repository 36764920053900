$colors: #32a852, #ff4d4d, #4dffb8, #f04dff, #4d4dff, #4dff94;

[class^='firework-'] {
  position: absolute;
  width: 1rem;
  height: 1rem;
  border-radius: 50%;
}

$count: 18;
$frame-list: firework-sm, firework-md, firework-lg;
@for $i from 1 through $count {
  .firework-#{$i} {
    animation: nth($frame-list, random(3)) 1.2s both infinite;
    animation-delay: #{random($count) * 0.1}s;
    top: random(16) * 5% + 10%;
    left: random(16) * 5% + 10%;
  }
}


// dots generator
@function dots($r) {
  $result: ();
  // straight
  @for $i from 1 through 4 {
    $x: 0;
    $y: 0;
    @if $i <= 2 {$x: $r} @else {$y: $r}
    @if $i % 2 != 0 {$x: -$x; $y: -$y}
    $result: append($result, #{$x}rem #{$y}rem 0 nth($colors, random(length($colors))), comma);
  }
  // diagonal
  @for $i from 1 through 4 {
    $dist2: $r * 0.7;
    $x: $dist2;
    $y: $dist2;
    @if $i > 2 {$x: -$x}
    @if $i % 2 != 0 {$y: -$y}
    $result: append($result, #{$x}rem #{$y}rem 0 nth($colors, random(length($colors))), comma);
  }
  @return $result;
}

// firework animation
@mixin fireworkSize($name, $r) {
  @keyframes firework-#{$name} {
    0%,
    100% {
      opacity: 0;
    }
    10%,
    70% {
      opacity: 1;
    }
    100% {
      box-shadow: dots($r);
    }
  }
}
@include fireworkSize(sm, 4.5);
@include fireworkSize(md, 6.7);
@include fireworkSize(lg, 8.9);