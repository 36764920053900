.div-inclinado {
    position: relative;
    width: 100%;
    height: 100vh; /* Ocupa el 100% de la altura del viewport */
    overflow: hidden; /* Para ocultar el exceso de la imagen inclinada */
  }
  
  .div-inclinado::before {
    content: "";
    position: absolute;
    top: -50%;
    width: 200%;
    height: 200%;
    background: url('https://filesharingprd.nyc3.cdn.digitaloceanspaces.com/public/demo/fondo-demo.jpg') no-repeat center center fixed;
    background-size: cover;
    transform: rotate(-10deg); /* Cambia el valor para ajustar el ángulo */
    transform-origin: center;
    z-index: -1; /* Asegúrate de que esté detrás del contenido */
  }
  