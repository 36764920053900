a:link,
a:visited,
a:hover,
a:active {
  text-decoration: none;
}

fieldset:not(.MuiOutlinedInput-notchedOutline) {
  border: 0 !important;
  padding: 0 !important;
  margin: 0 !important;
  min-width: 0 !important;
}

/*@font-face {
  font-family: 'DIN';
  src: url(./fonts/D-DIN.otf);
}*/


@font-face {
  font-family: 'TextaAlt';
  src: local('TextaAlt'), url(./fonts/TextaAlt-Medium.otf) format('opentype');
}


html {
  -webkit-tap-highlight-color: transparent; /* for removing the highlight */
}

body {
  /*font-family: "Nunito", Helvetica, Arial, sans-serif !important;*/
  font-family: 'TextaAlt';
  font-size: 16px !important;
  line-height: 1.42857143;
  color: #333333;
  -webkit-user-select: none;
  -webkit-tap-highlight-color: transparent;
  -webkit-touch-callout: none;
}
