.WordList {
  min-width: 200px;  
  max-width: 100%;
}

.WordList-heading {
  margin-top: 0px;
  font-size: 12px;
  letter-spacing: 0.1em;
  text-transform: uppercase;
  color: hsl(209, 91%, 79%);
  border-bottom: 1px solid hsl(212, 100%, 64%);
  margin-bottom: 0px;
  background-color: hsla(202, 86%, 66%, 0.315);
}

.WordList-emptyMessage {
  margin: 0.2em 0;
  font-size: 10px;
  color: hsl(237, 42%, 9%);
}

.WordList-list {
  margin-top: 1px !important;
  margin-bottom: 1px !important;
  list-style-type: none;
  float: left;
  background-color: #e1ebfd;
  margin-right: 5px;
  border: #8596e0 1px solid;
  color: black;
  padding: 0 0px;
  text-decoration: none;
  border-radius: 5%;
}

.WordList-list:hover {
  background-color: rgb(255, 146, 136);
  color: #D7C8B6;
}

.TextInput.WordList-addWord {
  width: 100%;
  margin-top: 6px;
  color: hsl(208, 90%, 12%)!important;
  border-bottom: 1px solid hsl(212, 100%, 64%)!important;
  background-color: hsla(202, 100%, 80%, 0.315)!important;
}

.WordList-finded {
  position: relative;
  display: inline-block;
  font-size: 15px;
  font-family: 'Source Code Pro', 'Roboto Mono', Consolas, Monaco, monospace;
  font-weight: 500;
  text-align: center;
  line-height: 1.5em;
  text-transform: uppercase;
  cursor: pointer;
  user-select: none;
  background-color: hsla(202, 89%, 55%, 0.315);
  border-radius: 2%;
  border: hsl(196, 19%, 23%);
}

.tachado {
  text-decoration: line-through;
}

 
