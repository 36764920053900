
  
  #podium-box {
    margin: 0 auto;
    display: flex;
    height: 300px
  }

  #podium-box p {
    text-transform: capitalize;
  }
  
  .podium-number {
    font-family: DaggerSquare;
    font-weight: bold;
    font-size: 2em;    
  }


  .podium-number p
  {
    margin: 0px !important;
  }
  
  .step-container {
    flex: 1;
    display: flex;
    flex-direction: column;
  }
  
  .step-container>div:first-child {
    margin-top: auto;
    text-align: center;
  }
  
  .step {
    text-align: center;
  }  

  #first-step {
    height: 50%;
  }
  
  #second-step {
    height: 35%;
  }
  
  #third-step {
    height: 30%;
  }

  .hall-fame-logo
  {
    width: 6rem;
  }

  .user-card {
    border-radius: 10px;
    background-color: #ffffff;
    min-height: 170px;
    display: flex;
    align-items: center;
    flex-direction: column;
    align-content: center;
    justify-content: flex-end;
  }