@import "~react-quill/dist/quill.snow.css";

.list-letter-soup {
  list-style: none;
  padding-left: 0px;
}

.carousel .control-arrow,
.carousel.carousel-slider .control-arrow {
  background-color: black !important;
}

.w-245 {
  width: 245px;
}

.custom-landing-bg {
  height: 100vh;
  background-image: url("../assets/img/peyastars-bg.jfif");
}

.peyastars-img {
  margin-bottom: -60px;
}

.misssion-span-votes {
  border: 5px solid blue;
  border-radius: 99px;
  background-color: white;
  padding: 7px 16px;
  font-weight: bold;
  font-size: 19px;
  margin-left: 3%;
}

.misssion-span-votes-list {
  margin-left: 5%;
  border: 5px solid blue;
  border-radius: 99px;
  background-color: white;
  padding: 5px 10px;
  font-weight: bold;
  font-size: 10px;
  color: #000;
}

.check-icon-pdf {
  color: #00b74a;
  font-size: 40px !important;
}

.link-icon-pdf {
  font-size: 50px !important;
}

.tbody-letter-soup {
  tr {
    th {
      background-color: #64b5f6;
      color: white;
    }
  }
}

.mb-1 {
  margin-bottom: 1rem;
}

.ql-editor {
  min-height: 300px;
}

.div-total-points {
  display: flex;
  justify-content: center;
}

.total-points {
  width: 160px;
  text-align: center;
  background-color: white;
  padding: 1em;
  border: 3px solid black;
}

body {
  margin: 0;
}

.border-radius-99 {
  border-radius: 99px;
}

.MuiDialog-container .MuiInputBase-formControl {
  background-color: white;
  margin-top: 5px;
}

.MuiDialog-container {
  header {
    background-color: white !important;
  }
}

.first-position {
  padding: 5px 10px !important;
  background-color: #ffd700 !important;
}

.second-position {
  padding: 5px 10px !important;
  background-color: #c0c0c0 !important;
}

.third-position {
  padding: 5px 10px !important;
  background-color: #cd7f32 !important;
}

/*****************Notifications**********/
.img-vote-icon {
  width: 17px !important;
  height: 17px !important;
}

.icon-vote-dialog {
  svg {
    width: 17px !important;
    height: 17px !important;
  }
}

.custom-width-avatar {
  width: 110px;
}

.title-username {
  height: 32px;
  overflow: hidden;
}
/*****************Notifications**********/
.list-language {
  background-color: white !important;
}

.challenges-banner {
  z-index: 1 !important;
}

.has-not-notifications {
  .MuiBadge-badge {
    background-color: white !important;
    border: 1px solid black !important;
    transform: scale(1) translate(50%, -50%) !important;
    color: transparent !important;
  }
}

.img-user-comment {
  width: 80px;
  height: 80px;
  border-radius: 99px;
}

.badge-div {
  text-align: center;
  margin-top: -60px;
  border-radius: 99px;
  min-height: 50px;
  display: flex;
  justify-content: space-evenly;
  align-items: center;

  img {
    width: 40px;
  }
}

.d-flex-user-comment {
  display: flex;
  align-items: center;
  border: 1px solid black;
  border-radius: 5px;
  padding-top: 5px;
  padding-bottom: 5px;
}

.bg-without-points {
  opacity: 0.5;
}

.not-margin {
  margin: 0px !important;
}

.vl {
  border-left: 6px solid black;
  height: 8%;
  position: absolute;
  margin-left: 2rem;
}

.span-custom-position {
  border-radius: 99px;
  padding: 1px 7px;
  border: 1px solid black;
  font-weight: bold;
  margin-right: 3px;
}

.custom-cart-dashboard {
  margin-top: 0px;
  text-align: right;
  margin-bottom: 0px;
  margin-right: 0.5rem;
  margin-left: 0.5rem;
  font-weight: bold;
}

.image-custom-width {
  width: 400px;
}

.name-custom {
  min-width: 33%;
  display: flex;
  align-items: center;
}

.justify-content-end {
  justify-content: flex-end;
}

.justify-content-center {
  justify-content: center;
}

.ranking-list-content {
  z-index: 1;
  position: relative;
}

.bg-custom-padding {
  padding: 0 !important;
}

.custom-margin-top-panel {
  margin-top: 0.5rem !important;
}

.resume-custom {
  overflow-x: hidden;
  overflow-y: auto;
  display: flex;
  flex-wrap: wrap;

  .new-cart-wrapper {
    flex: 48%;
    margin: 5px;
  }
}

.custom-z-index {
  z-index: 1;
}

.fade-enter {
  position: absolute;
  opacity: 0;
  z-index: 1;
}

.fade-enter.fade-enter-active {
  position: absolute;
  opacity: 1;
  transition: opacity 250ms ease-in;
}

.border-dotted {
  margin-bottom: 1em;
  box-sizing: border-box;
  background-color: var(--warm-purple);
  border-radius: 0.5em;
  width: 100%;
  display: block;
  padding: 0.25em;
  .dots {
    border: 5px dotted #fff;
  }
  .content {
    min-width: calc(100% - 0.5em);
    height: fit-content;
    background-color: #fff;
    display: block;
    border-radius: 0.25em;
    margin: 0.25em;
    box-sizing: border-box;
    padding: 0.5em;
    color: var(--warm-purple);
    font-style: oblique;
    font-size: 0.75em;
  }
}
.oblique {
  font-style: oblique;
}
.bold {
  font-weight: bold;
}
.pointer {
  cursor: pointer;
}

.d-flex {
  display: flex !important;
  align-items: center !important;
}

.d-flex-between {
  display: flex !important;
  align-content: center;
  justify-content: space-between;
  align-items: center;
}

.d-flex-custom {
  display: flex !important;
}

.custom-height {
  height: 540px;
  overflow-y: auto;
  overflow-x: hidden;
}

.icon {
  width: 1em;
  //display: flex;
  img {
    width: 100%;
  }
}
.admin-wrapper {
  .MuiBadge-anchorOriginTopRightRectangle {
    background-color: #000;
    color: #fff;
  }

  .MuiButtonBase-root.MuiButton-root.MuiButton-contained.MuiButton-containedSecondary,
  .MuiButtonBase-root.MuiButton-root.MuiButton-contained.MuiButton-containedPrimary {
    cursor: pointer;
    background: var(--secondary-btn-bg);
    padding: 0.5em 1.5em;
    font-weight: 600;
    color: #fff;
    display: flex;
    justify-content: center;
    text-align: center;
    height: max-content;
    box-sizing: border-box;
    transition: all 0.5s;
    box-shadow: none;
    border-radius: 0%;
  }
  .MuiButtonBase-root.MuiButton-root.MuiButton-contained.MuiButton-containedPrimary {
    background: var(--primary-btn-bg);
  }
  .MuiPaper-root {
    box-shadow: none !important;
    border-bottom: solid 1px #e1e1e1;
  }
  .MuiToolbar-root.MuiToolbar-regular.MuiToolbar-gutters {
    background-color: #fff;
    color: var(--warm-purple);
    box-shadow: none;
    color: var(--main-blue);
  }
  .MuiTableContainer-root {
    padding: 1em;
    border: 2px solid var(--warm-purple);
  }
}
/* class applies to select element itself, not a wrapper element */
.select-css:disabled {
  filter: saturate(0);
}
.select-css {
  display: block;
  font-size: 1em;
  color: var(--warm-purple);
  line-height: 1.3;
  padding: 0.6em 1.4em 0.5em 0.8em;
  width: 100%;
  max-width: 100%; /* useful when width is set to anything other than 100% */
  box-sizing: border-box;
  margin: 0;
  border: 0px solid #aaa;
  box-shadow: 0 1px 0 1px rgba(0, 0, 0, 0.04);
  border-radius: 0.25em;
  -moz-appearance: none;
  -webkit-appearance: none;
  appearance: none;
  background-color: #fff;
  margin-bottom: 1em;
  margin-top: 0.5em;
  /* note: bg image below uses 2 urls. The first is an svg data uri for the arrow icon, and the second is the gradient. 
      for the icon, if you want to change the color, be sure to use `%23` instead of `#`, since it's a url. You can also swap in a different svg icon or an external image reference
      
    */
  background-image: url("data:image/svg+xml;charset=US-ASCII,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%22292.4%22%20height%3D%22292.4%22%3E%3Cpath%20fill%3D%22%23007CB2%22%20d%3D%22M287%2069.4a17.6%2017.6%200%200%200-13-5.4H18.4c-5%200-9.3%201.8-12.9%205.4A17.6%2017.6%200%200%200%200%2082.2c0%205%201.8%209.3%205.4%2012.9l128%20127.9c3.6%203.6%207.8%205.4%2012.8%205.4s9.2-1.8%2012.8-5.4L287%2095c3.5-3.5%205.4-7.8%205.4-12.8%200-5-1.9-9.2-5.5-12.8z%22%2F%3E%3C%2Fsvg%3E"),
    linear-gradient(to bottom, #d6c5ff 0%, #fde6ff 100%);
  background-repeat: no-repeat, repeat;
  /* arrow icon position (1em from the right, 50% vertical) , then gradient position*/
  background-position: right 0.7em top 50%, 0 0;
  /* icon size, then gradient */
  background-size: 0.65em auto, 100%;
}
/* Hide arrow icon in IE browsers */
.select-css::-ms-expand {
  display: none;
}
/* Hover style */
.select-css:hover {
  border-color: #888;
}
/* Focus style */
.select-css:focus {
  border-color: #aaa;
  /* It'd be nice to use -webkit-focus-ring-color here but it doesn't work on box-shadow */
  box-shadow: 0 0 1px 3px rgba(59, 153, 252, 0.7);
  box-shadow: 0 0 0 3px -moz-mac-focusring;
  color: var(--warm-purple);
  outline: none;
}

/* Set options to normal weight */
.select-css option {
  font-weight: normal;
}

/* Support for rtl text, explicit support for Arabic and Hebrew */
*[dir="rtl"] .select-css,
:root:lang(ar) .select-css,
:root:lang(iw) .select-css {
  background-position: left 0.7em top 50%, 0 0;
  padding: 0.6em 0.8em 0.5em 1.4em;
}

/* Disabled styles */
.select-css:disabled,
.select-css[aria-disabled="true"] {
  color: red;
  background-image: url("data:image/svg+xml;charset=US-ASCII,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%22292.4%22%20height%3D%22292.4%22%3E%3Cpath%20fill%3D%22graytext%22%20d%3D%22M287%2069.4a17.6%2017.6%200%200%200-13-5.4H18.4c-5%200-9.3%201.8-12.9%205.4A17.6%2017.6%200%200%200%200%2082.2c0%205%201.8%209.3%205.4%2012.9l128%20127.9c3.6%203.6%207.8%205.4%2012.8%205.4s9.2-1.8%2012.8-5.4L287%2095c3.5-3.5%205.4-7.8%205.4-12.8%200-5-1.9-9.2-5.5-12.8z%22%2F%3E%3C%2Fsvg%3E"),
    linear-gradient(to bottom, #ffffff 0%, #e5e5e5 100%);
}

.select-css:disabled:hover,
.select-css[aria-disabled="true"] {
  border-color: #aaa;
}

body {
  margin: 2rem;
}

.icon-topBar .MuiIconButton-root {
  color: white !important;
}

/*********New Styles in administration Panel*********/

.metric-password-player {
  padding: 0px 15px 0px 15px;
}

.img-header {
  height: 64px;
}

.badge-icon-container {
  img {
    width: 90px !important;
    height: 90px !important;
  }
}

/*DashBoard*/
.img-system-container {
  height: 64px;
  padding: 0.5em;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  .img-system {
    height: calc(100% - 0.5em);
  }
}

.div-img-avatar {
  text-align: center;
  .img-avatar {
    width: 6rem;
  }
}

.button-dropDown svg {
  color: white;
}

/***********General***************/
.m-0 {
  margin: 0px !important;
}

.p-0 {
  padding: 0px !important;
}

.p-1 {
  padding: 0.5rem !important;
}

.mt-0 {
  margin-top: 0px !important;
}

.mb-0 {
  margin-bottom: 0px !important;
}

.not-delivered {
  opacity: 0.8 !important;
  background: #bdbdbd !important;
}

.badge-content-custom {
  margin: 0.5em;
  box-sizing: border-box;
  border-radius: 0.5em;
  padding: 0.5em;
  transition: all 0.5s;
  color: #fff;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.badge-wrapper .badge-content-custom .badge-icon-container {
  display: flex;
  position: relative;
  width: 100%;
}

.badge-wrapper .badge-content-custom .badge-points {
  margin-top: 0.25em;
  font-weight: bold;
  text-align: center;
}

.table-container-custom {
  z-index: 99 !important;
}

.bg-pink {
  background-color: var(--light-purple) !important;
  color: white !important;
}

.title-modal {
  margin: 0px !important;
  width: 100% !important;
}

.div-img-designed {
  text-align: center !important;
}

.img-predesigned {
  height: 86% !important;
  width: 59% !important;
}

.img-image {
  width: 100%;
  height: 160px;
}

.hr-styles {
  width: 100%;
  border: 1px solid black;
}

p.MuiFormHelperText-root {
  margin-left: 0px !important;
  margin-right: 0px !important;
}

.grid-test .MuiFormControl-root {
  width: 100% !important;
  margin-top: 1rem !important;
}

.custom-margin-top {
  margin-top: 1px !important;
}

.contained-button-file {
  display: none !important;
}
.img-rounded {
  width: 6rem;
  height: 7em;
  border-radius: 99px;
}

.padding-custom-button {
  padding-top: 1.2rem !important;
}

.cursor-pointer {
  cursor: pointer !important;
}

.h-100 {
  height: 100% !important;
}

.w-100 {
  width: 100% !important;
}

.div-d-flex {
  display: flex !important;
  align-items: center !important;
}

.mt-1 {
  margin-top: 0.5rem !important;
}

.mt-2 {
  margin-top: 1rem !important;
}

.mb-0 {
  margin-bottom: 0rem !important;
}

.mt-0 {
  margin-top: 0rem !important;
}

.mb-2 {
  margin-bottom: 1rem !important;
}

.mb-3 {
  margin-bottom: 3rem !important;
}

a {
  color: inherit;
}

.ml-1,
.ml-custom-1 {
  margin-left: 1rem !important;
}

.mr-05 {
  margin-right: 0.5rem !important;
}

.mr-1 {
  margin-right: 1rem !important;
}

.mr-2 {
  margin-right: 2rem !important;
}

.font-custom {
  font-size: 15px;
}

.not-register-div {
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.input-error {
  border: 1px solid red !important;
}

.button-table {
  margin-bottom: 8px !important;
  height: 55px !important;
}

@media (max-width: 500px) {
  .ml-1 {
    margin-left: 0px !important;
  }
  .mr-1 {
    margin-right: 0px !important;
  }

  .mb-0 {
    margin-bottom: 0.5rem !important;
  }
  .button-table {
    width: 100%;
  }
  .link-custom {
    width: 100%;
  }
}

.button-table-report {
  margin-bottom: 8px !important;
  margin-left: 1rem !important;
}

.button-table-custom {
  display: flex;
  align-items: center;
}

.padding-custom {
  margin-left: 1.6rem !important;
}

.form-control-custom {
  width: 31%;
}

.form-control-custom-analytic {
  width: 25.6%;
}

.pagination-custom-margin {
  margin-bottom: 2rem;
}

@media (max-width: 1100px) {
  .padding-custom {
    margin-left: 0rem !important;
    margin-bottom: 10px !important;
  }
}

@media (max-width: 937px) {
  .button-table-report {
    width: 100%;
    margin-left: 0 !important;
    margin-right: 0 !important;
  }
  .padding-custom {
    margin-left: 0rem !important;
  }
}

@media (max-width: 687px) {
  .link-custom {
    width: 100% !important;
  }

  .form-control-custom-analytic {
    width: 100% !important;
    margin-bottom: 10px !important;
  }
}

@media (max-width: 500px) {
  .link-custom {
    width: 100% !important;
  }

  .form-control-custom {
    width: 100% !important;
    margin-bottom: 10px !important;
  }
}

.danger-custom {
  background-color: #f93154 !important;
}

.text-center {
  text-align: center;
}

/**********Configuration- Design*****************/

.doneList {
  color: green;
  font-weight: bold;
}

.rug-handle-drop-text {
  text-indent: -9999px;
  line-height: 0; /* Collapse the original line */
}

.rug-handle-drop-text::after {
  content: "Arrastra tu imagen";
  text-indent: 0;
  display: block;
  line-height: initial; /* New content takes up original line height */
}
.rug-handle-info span {
  text-indent: -9999px;
  line-height: 0; /* Collapse the original line */
}

.rug-handle-info span::after {
  content: "ó";
  text-indent: 0;
  display: block;
  line-height: initial; /* New content takes up original line height */
}

.rug-handle-button {
  text-indent: -9999px;
  line-height: 0; /* Collapse the original line */
}

.rug-handle-button::after {
  content: "Selecciona tu imagen";
  text-indent: 0;
  display: block;
  line-height: initial; /* New content takes up original line height */
}

.rug-items.__card.__sorting {
  border: 1px solid black;
  margin: 1px;
  border-radius: 20px;
}

.divider {
  width: 99%;
}

/**************User Panel****************/

.fileInputUser {
  display: none;
}

.div-spinner-modal {
  .MuiGrid-container {
    display: flex;
    height: 55vh;
    align-content: center;
    justify-content: center;
  }
}

.spinner-container {
  width: 100%;
  display: flex;
  justify-content: center;
  position: relative;
  svg {
    color: var(--warm-purple) !important;
  }
}

.spinner-container-custom {
  width: 500%;
  display: flex;
  justify-content: center;
  position: relative;
  svg {
    color: var(--warm-purple) !important;
  }
}

.div-spinner {
  .MuiGrid-container {
    display: flex;
    height: 80vh;
    align-content: center;
    justify-content: center;
  }
}

.custom-margin-top {
  margin-top: 4rem !important;
}

.div-spinner-consolidated {
  .MuiGrid-container {
    display: flex;
    height: 40vh;
    align-content: center;
    justify-content: center;
  }
}

@media (max-width: 500px) {
  .row-display-none {
    display: none !important;
  }
  .link-button {
    width: 100%;
  }
}

.input-text-container {
  width: 100%;
  display: flex;
  justify-content: center;
  input {
    padding: 0.5em 1em;
    border: 2px solid var(--warm-purple);
    border-radius: 0.5em;
    width: 70%;
  }
  margin-bottom: 1em;
}

/************Dialog import Users**********/

.dialog-errors {
  display: flex !important;
  margin-top: 0px !important;
}

.admin-wrapper {
  .MuiBadge-anchorOriginTopRightRectangle {
    background-color: #000;
    color: #fff;
  }

  .MuiButtonBase-root.MuiButton-root.MuiButton-contained.MuiButton-containedSecondary,
  .MuiButtonBase-root.MuiButton-root.MuiButton-contained.MuiButton-containedPrimary {
    cursor: pointer;
    background: var(--secondary-btn-bg);
    padding: 0.5em 1.5em;
    font-weight: 600;
    color: #fff;
    display: flex;
    justify-content: center;
    text-align: center;
    height: max-content;
    box-sizing: border-box;
    transition: all 0.5s;
    box-shadow: none;
    border-radius: 0%;
  }
  .MuiButtonBase-root.MuiButton-root.MuiButton-contained.MuiButton-containedPrimary {
    background: var(--primary-btn-bg);
  }
  .MuiPaper-root {
    box-shadow: none !important;
    border-bottom: solid 1px #e1e1e1;
  }
  .MuiToolbar-root.MuiToolbar-regular.MuiToolbar-gutters {
    background-color: #fff;
    color: var(--warm-purple);
    box-shadow: none;
    color: var(--main-blue);
  }
  .MuiTableContainer-root {
    padding: 1em;
    border: 2px solid var(--warm-purple);
  }
  .MuiDrawer-paper {
    min-height: 100vh;
  }
}

.MuiToolbar-root.MuiToolbar-regular.MuiPickersToolbar-toolbar.MuiPickersDatePickerRoot-toolbar.MuiToolbar-gutters,
.MuiButtonBase-root.MuiIconButton-root.MuiPickersDay-day.MuiPickersDay-current.MuiPickersDay-daySelected,
.MuiButtonBase-root.MuiIconButton-root.MuiPickersDay-day.MuiPickersDay-daySelected {
  background-color: var(--warm-purple);
}

.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
  border-color: var(--warm-purple) !important;
}

.bets-page-wrapper,
.challenge-admin-wrapper,
.new-mission-wrapper {
  .MuiButtonBase-root.MuiButton-root.MuiButton-text.MuiButton-textPrimary,
  .MuiInputBase-input.MuiInput-input {
    color: var(--warm-purple);
  }
  .MuiInputBase-input.MuiInput-input::after {
    border-width: 0 !important;
  }
  .MuiInput-underline::after,
  .MuiInput-underline::before {
    border-width: 0 !important;
  }
  .MuiInputBase-input.MuiInput-input {
    border: 1px solid var(--warm-purple);
    border-radius: 0.5em;
    padding: 0.5em 1em;
  }
  .MuiButtonBase-root.MuiIconButton-root {
    color: var(--warm-purple);
  }
}

.MuiPaper-root.MuiAppBar-root.MuiAppBar-positionFixed.MuiAppBar-colorPrimary.mui-fixed.MuiPaper-elevation4 {
  background-color: var(--warm-purple);
}

.MuiStepIcon-root.MuiStepIcon-active,
.MuiStepIcon-root.MuiStepIcon-completed {
  color: var(--warm-purple) !important;
}

.hsCati,
.sc-hBEYos {
  background-color: white !important;
}

.fCThRJ,
.sc-fodVxV {
  border-top: 0.5px solid black;
  border-right: 0.5px solid black;
  border-left: 0.5px solid black;
}

.eZhFZb,
.sc-crrsfI {
  width: 108%;
  overflow: scroll;
}

.img-dashboard {
  width: 55px;
}

.d-none {
  display: none !important;
}

@media screen and (max-width: 900px) and (min-width: 600px) {
  div.example {
    font-size: 50px;
    padding: 50px;
    border: 8px solid black;
    background: yellow;
  }
}

@media screen and (max-width: 991px) {
  .tournament-wrapper {
    display: block !important;
  }
}

@media screen and (max-width: 530px) {
  .img-user-comment {
    display: none;
  }
}

@media screen and (max-width: 500px) {
  .custom-text-field-user {
    width: 100%;
  }
}

.font-weight-bold {
  font-weight: bold !important;
}

@keyframes shake-slow {
  2% {
    transform: translate(0px, -7px) rotate(2.5deg);
  }
  4% {
    transform: translate(6px, 3px) rotate(-0.5deg);
  }
  6% {
    transform: translate(6px, -5px) rotate(0.5deg);
  }
  8% {
    transform: translate(3px, 3px) rotate(-1.5deg);
  }
  10% {
    transform: translate(-4px, 5px) rotate(1.5deg);
  }
  12% {
    transform: translate(2px, 7px) rotate(2.5deg);
  }
  14% {
    transform: translate(0px, 6px) rotate(-1.5deg);
  }
  16% {
    transform: translate(-9px, 5px) rotate(-2.5deg);
  }
  18% {
    transform: translate(4px, -8px) rotate(-0.5deg);
  }
  20% {
    transform: translate(2px, 9px) rotate(3.5deg);
  }
  22% {
    transform: translate(-5px, 1px) rotate(-2.5deg);
  }
  24% {
    transform: translate(-2px, -8px) rotate(0.5deg);
  }
  26% {
    transform: translate(4px, -2px) rotate(-0.5deg);
  }
  28% {
    transform: translate(-4px, 9px) rotate(1.5deg);
  }
  30% {
    transform: translate(-4px, -3px) rotate(3.5deg);
  }
  32% {
    transform: translate(-2px, 6px) rotate(-2.5deg);
  }
  34% {
    transform: translate(4px, -4px) rotate(-0.5deg);
  }
  36% {
    transform: translate(-1px, 6px) rotate(0.5deg);
  }
  38% {
    transform: translate(8px, 8px) rotate(1.5deg);
  }
  40% {
    transform: translate(9px, -2px) rotate(3.5deg);
  }
  42% {
    transform: translate(-2px, -9px) rotate(0.5deg);
  }
  44% {
    transform: translate(-1px, 10px) rotate(-1.5deg);
  }
  46% {
    transform: translate(-1px, 1px) rotate(-0.5deg);
  }
  48% {
    transform: translate(6px, -8px) rotate(2.5deg);
  }
  50% {
    transform: translate(-1px, -7px) rotate(-1.5deg);
  }
  52% {
    transform: translate(0px, 1px) rotate(-1.5deg);
  }
  54% {
    transform: translate(1px, -8px) rotate(-2.5deg);
  }
  56% {
    transform: translate(-4px, 2px) rotate(1.5deg);
  }
  58% {
    transform: translate(10px, -7px) rotate(-2.5deg);
  }
  60% {
    transform: translate(-2px, -4px) rotate(-1.5deg);
  }
  62% {
    transform: translate(-3px, 3px) rotate(1.5deg);
  }
  64% {
    transform: translate(8px, 2px) rotate(-1.5deg);
  }
  66% {
    transform: translate(-4px, -1px) rotate(1.5deg);
  }
  68% {
    transform: translate(-1px, -2px) rotate(-1.5deg);
  }
  70% {
    transform: translate(8px, 8px) rotate(0.5deg);
  }
  72% {
    transform: translate(-8px, -3px) rotate(-2.5deg);
  }
  74% {
    transform: translate(6px, 5px) rotate(0.5deg);
  }
  76% {
    transform: translate(4px, -9px) rotate(1.5deg);
  }
  78% {
    transform: translate(-2px, -6px) rotate(3.5deg);
  }
  80% {
    transform: translate(1px, 0px) rotate(1.5deg);
  }
  82% {
    transform: translate(-4px, 6px) rotate(-2.5deg);
  }
  84% {
    transform: translate(-4px, -3px) rotate(1.5deg);
  }
  86% {
    transform: translate(7px, 10px) rotate(2.5deg);
  }
  88% {
    transform: translate(-3px, -2px) rotate(1.5deg);
  }
  90% {
    transform: translate(8px, -3px) rotate(3.5deg);
  }
  92% {
    transform: translate(0px, 3px) rotate(1.5deg);
  }
  94% {
    transform: translate(5px, -5px) rotate(-2.5deg);
  }
  96% {
    transform: translate(7px, -2px) rotate(-0.5deg);
  }
  98% {
    transform: translate(-6px, 0px) rotate(3.5deg);
  }
  0%,
  100% {
    transform: translate(0, 0) rotate(0);
  }
}

@keyframes shake-opacity {
  10% {
    transform: translate(1px, -2px) rotate(1.5deg);
    opacity: 0.95;
  }
  20% {
    transform: translate(-4px, 5px) rotate(-0.5deg);
    opacity: 0.8;
  }
  30% {
    transform: translate(4px, -2px) rotate(-1.5deg);
    opacity: 0.62;
  }
  40% {
    transform: translate(-1px, 2px) rotate(-1.5deg);
    opacity: 0.97;
  }
  50% {
    transform: translate(5px, 0px) rotate(1.5deg);
    opacity: 0.97;
  }
  60% {
    transform: translate(2px, -4px) rotate(1.5deg);
    opacity: 0.24;
  }
  70% {
    transform: translate(-3px, 3px) rotate(1.5deg);
    opacity: 0.59;
  }
  80% {
    transform: translate(2px, -2px) rotate(-0.5deg);
    opacity: 0.03;
  }
  90% {
    transform: translate(2px, -4px) rotate(-0.5deg);
    opacity: 0.05;
  }
  0%,
  100% {
    transform: translate(0, 0) rotate(0);
  }
}

@keyframes shake-basic {
  2% {
    transform: translate(1.5px, -1.5px) rotate(-0.5deg);
  }
  4% {
    transform: translate(-1.5px, -0.5px) rotate(1.5deg);
  }
  6% {
    transform: translate(1.5px, 2.5px) rotate(-0.5deg);
  }
  8% {
    transform: translate(2.5px, 0.5px) rotate(-0.5deg);
  }
  10% {
    transform: translate(2.5px, -0.5px) rotate(-0.5deg);
  }
  12% {
    transform: translate(-0.5px, 1.5px) rotate(1.5deg);
  }
  14% {
    transform: translate(2.5px, -0.5px) rotate(1.5deg);
  }
  16% {
    transform: translate(0.5px, -1.5px) rotate(0.5deg);
  }
  18% {
    transform: translate(2.5px, 2.5px) rotate(-0.5deg);
  }
  20% {
    transform: translate(1.5px, -0.5px) rotate(1.5deg);
  }
  22% {
    transform: translate(-0.5px, 1.5px) rotate(0.5deg);
  }
  24% {
    transform: translate(-1.5px, 1.5px) rotate(0.5deg);
  }
  26% {
    transform: translate(2.5px, 0.5px) rotate(1.5deg);
  }
  28% {
    transform: translate(-0.5px, -0.5px) rotate(0.5deg);
  }
  30% {
    transform: translate(0.5px, -0.5px) rotate(0.5deg);
  }
  32% {
    transform: translate(1.5px, -0.5px) rotate(1.5deg);
  }
  34% {
    transform: translate(1.5px, 0.5px) rotate(-0.5deg);
  }
  36% {
    transform: translate(0.5px, 2.5px) rotate(0.5deg);
  }
  38% {
    transform: translate(-1.5px, -0.5px) rotate(0.5deg);
  }
  40% {
    transform: translate(0.5px, -0.5px) rotate(0.5deg);
  }
  42% {
    transform: translate(0.5px, -0.5px) rotate(1.5deg);
  }
  44% {
    transform: translate(-1.5px, 0.5px) rotate(1.5deg);
  }
  46% {
    transform: translate(-1.5px, -0.5px) rotate(-0.5deg);
  }
  48% {
    transform: translate(-1.5px, -0.5px) rotate(-0.5deg);
  }
  50% {
    transform: translate(-0.5px, -0.5px) rotate(0.5deg);
  }
  52% {
    transform: translate(-1.5px, 0.5px) rotate(1.5deg);
  }
  54% {
    transform: translate(2.5px, 0.5px) rotate(-0.5deg);
  }
  56% {
    transform: translate(1.5px, 2.5px) rotate(1.5deg);
  }
  58% {
    transform: translate(0.5px, 1.5px) rotate(1.5deg);
  }
  60% {
    transform: translate(-1.5px, -1.5px) rotate(1.5deg);
  }
  62% {
    transform: translate(0.5px, 1.5px) rotate(-0.5deg);
  }
  64% {
    transform: translate(1.5px, 2.5px) rotate(1.5deg);
  }
  66% {
    transform: translate(1.5px, -0.5px) rotate(1.5deg);
  }
  68% {
    transform: translate(-1.5px, 1.5px) rotate(0.5deg);
  }
  70% {
    transform: translate(0.5px, 0.5px) rotate(-0.5deg);
  }
  72% {
    transform: translate(-0.5px, -0.5px) rotate(0.5deg);
  }
  74% {
    transform: translate(0.5px, -1.5px) rotate(0.5deg);
  }
  76% {
    transform: translate(-0.5px, 1.5px) rotate(0.5deg);
  }
  78% {
    transform: translate(1.5px, -1.5px) rotate(-0.5deg);
  }
  80% {
    transform: translate(-1.5px, -0.5px) rotate(0.5deg);
  }
  82% {
    transform: translate(0.5px, -0.5px) rotate(0.5deg);
  }
  84% {
    transform: translate(2.5px, 2.5px) rotate(1.5deg);
  }
  86% {
    transform: translate(2.5px, -0.5px) rotate(1.5deg);
  }
  88% {
    transform: translate(0.5px, 0.5px) rotate(-0.5deg);
  }
  90% {
    transform: translate(-0.5px, -1.5px) rotate(-0.5deg);
  }
  92% {
    transform: translate(2.5px, 1.5px) rotate(-0.5deg);
  }
  94% {
    transform: translate(2.5px, -1.5px) rotate(-0.5deg);
  }
  96% {
    transform: translate(2.5px, 2.5px) rotate(0.5deg);
  }
  98% {
    transform: translate(2.5px, -0.5px) rotate(0.5deg);
  }
  0%,
  100% {
    transform: translate(0, 0) rotate(0);
  }
}
.bg {
  * {
    color: #000;
  }
}
