.steep-container.tournament{
    margin-top: 1em;
    width: 100%;
    background-color: #fff;
    box-sizing: border-box;
    border: 2px solid var(--warm-purple);
    border-radius: .25em;
    padding: .5em 1em;
    margin-bottom: 1em;
    display: flex;
    flex-direction: column;
    .tournament-input{
        margin-bottom: 1em;
    }
}
.admin-wrapper .MuiPaper-root{
    border: 0 !important;
}
.user-detail-wrapper{
    padding: 1em;
    background-color: #fff;
}

.user-mission-preview{
  width: 100%;
  text-align: center;
  img {
    width: 100%;
    height: 200px;
  }
}

.pending-bets-table{
    width: 100%;
    border: 0;
    thead{
      th{
        font-weight: bold;
        text-align: left;
      }
    }
    .table-content{
      .gambler, .opponent{
        display: flex;
        align-items: center;
        .icon{
          width: 1.5em;
          display: flex;
          margin-right: 1em;
          img{
            width: 100%;
          }
        }
      }
      .status{
        padding: .25em 1em;
        border-radius: 1em;
        background-color: var(--main-blue);
        font-size: .75em;
      }
      .pending{
        background-color: #fdcf00;
      }
      .rejected{
        color: #fff;
        background-color: #e7017e;
      }
      .bet-crud{
        display: flex;
        justify-content: space-between;
        .crud-icon{
          width: 1em;
          img{
            width: 100%;
          }
        }
      }
      td{
        max-width: 10em;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }
  }