.Letter {
  position: relative;
  display: inline-block;
  width: 1.5em;
  font-size: 20px;
  font-family: 'Source Code Pro', 'Roboto Mono', Consolas, Monaco, monospace;
  font-weight: 600;
  text-align: center;
  line-height: 1.5em;
  text-transform: uppercase;
  cursor: pointer;
  user-select: none;
  background-color: rgba(255, 255, 255, 1);
  border-radius: 50%;
  border: cornsilk;
}

.Letter:hover {
  background-color: rgba(12, 151, 82, 0.1);
}

.color{
  background-color:rgb(26, 24, 24);
}

.color-letter-soup-1{
  background-color:rgb(248, 132, 55);
}
.color-letter-soup-2{
  background-color:rgb(79, 189, 253);
}
.color-letter-soup-3{
  background-color:rgb(79, 189, 94);
}
.color-letter-soup-4{
  background-color:rgb(191, 124, 253);
}

.color-letter-soup-5{
  background-color:rgb(49, 79, 252);
}
.color-letter-soup-6{
  background-color:rgb(6, 232, 248);
}

.color-letter-soup-8{
  background-color:rgb(219, 161, 74);
}
.color-letter-soup-9{
  background-color:rgb(247, 160, 134);
}

.color-letter-soup-10{
  background-color:rgb(139, 46, 46);
}
.color-letter-soup-11{
  background-color:rgb(91, 128, 68);
}

.color-letter-soup-12{
  background-color:rgb(42, 165, 69);
}
.color-letter-soup-13{
  background-color:rgba(81, 52, 139, 0.541);
}
.color-letter-soup-14{
  background-color:rgb(228, 194, 3);
}
.color-letter-soup-15{
  background-color:rgb(165, 112, 77);
}
.color-letter-soup-16{
  background-color:rgb(41, 17, 1);
}
.color-letter-soup-17{
  background-color:rgb(97, 2, 54);
}
.color-letter-soup-18{
  background-color:rgb(2, 121, 121);
}
.color-letter-soup-19{
  background-color:rgb(1, 46, 8);
}
.color-letter-soup-20{
  background-color:rgb(4, 17, 139);
}

.Letter-highlight {
  position: absolute;
  top: 3px;
  right: 3px;
  bottom: 3px;
  left: 3px;
  z-index: -2;
  background-color: var(--theme-color);
  content: '';
  border-radius: 0;
}

.Letter-highlight--joinN,
.Letter-highlight--joinNE,
.Letter-highlight--joinNW,
.Letter-highlight--joinE,
.Letter-highlight--joinS,
.Letter-highlight--joinSE,
.Letter-highlight--joinSW,
.Letter-highlight--joinW {
  z-index: -1;
  border-radius: 0;
}

.Letter-highlight--joinN {
  top: auto;
  bottom: 50%;
  height: 100%;
}

.Letter-highlight--joinNE {
  left: auto;
  right: 50%;
  width: 140%;
  transform: rotate(45deg);
  transform-origin: right center;
}

.Letter-highlight--joinNW {
  left: 50%;
  right: auto;
  width: 140%;
  transform: rotate(-45deg);
  transform-origin: left center;
}

.Letter-highlight--joinE {
  left: auto;
  right: 50%;
  width: 100%;
}

.Letter-highlight--joinS {
  top: 50%;
  bottom: auto;
  height: 100%;
}

.Letter-highlight--joinSE {
  left: auto;
  right: 50%;
  width: 140%;
  transform: rotate(-45deg);
  transform-origin: right center;
}

.Letter-highlight--joinSW {
  left: 50%;
  right: auto;
  width: 140%;
  transform: rotate(45deg);
  transform-origin: left center;
}

.Letter-highlight--joinW {
  left: 50%;
  right: auto;
  width: 100%;
}
