.titleTable {
  margin: 0;
  margin-bottom: 1rem;
  margin-left: 1.5rem;
}

.titleTableCustom {
  margin: 0;
  margin-bottom: 1rem;
}
