.body-image{
    display:flex;
    justify-content: center;
    padding: 20px 10px 10px 10px;
    img{
        width:100px;
    }
}
.actions-buttons-dialogstepper{
    display: flex;
    justify-content: center;
    .button-next{
        background-color: white;
        border-color: #0A5DFF;
        line-height: 1.6rem;
        border-radius: 40px;
        border-style: solid;
        border-width: 1px;
        padding: 10px 30px;
        color: #0A5DFF !important;
        font-weight: 700;
        margin: 0px 10px;
        //margin: 16px;
        
    }
    .button-next:hover{
        color: white !important;
        background-color: #0A5DFF !important;
        border-color: #0A5DFF !important;
        border-style: solid;
        border-width: 1px;
    }
    .button-back{
        background-color: white;
        border-color: #636363;
        line-height: 1.6rem;
        border-radius: 40px;
        border-style: solid;
        border-width: 1px;
        padding: 10px 30px;
        color: #636363 !important;
        font-weight: 700;
        margin: 0px 10px;        
    }
}
@media only screen and (max-width: 400px){
    .dialog-content-asign-badge {
        padding: 16px 10px !important;
    }
    .stepper-asign-badge{
        padding: 24px 0px !important;
    }
}